import React, { Component } from "react";
import { Rect, Text, Group, Image, Circle } from "react-konva";
import CardDefault from "../../../../../../assets/games/russianPoker/rp_sprite_card.png";

import DealerCard from "./dealerCard"


class DealerCardsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.width = window.innerWidth * 0.5;
        this.state = {
            x: this.width - 26,
            y: 50,
            cardCount: 2,
            isFront: true,
            cardZeroAlpha: 1,
            cardOneAlpha: 1,
            cardTwoAlpha: 1,
            cardThreeAlpha: 1,
            cardFourAlpha: 1,
            cardFiveAlpha: 1,
            playerCardZero: "TC",
            playerCardOne: "TS",
            playerCardTwo: "TD",
            playerCardThree: "TC",
            playerCardFour: "TC",
            playerCardFive: "TC",
            cardStyle: CardDefault,
        };
        this.dealerCardRef = React.createRef();

        this.countcards = 2;
        this.mapCards = {
            "AC": [0, 0, 53, 73],
            "2C": [53, 0, 53, 73],
            "3C": [106, 0, 53, 73],
            "4C": [159, 0, 53, 73],
            "5C": [212, 0, 53, 73],
            "6C": [265, 0, 53, 73],
            "7C": [318, 0, 53, 73],
            "8C": [371, 0, 53, 73],
            "9C": [424, 0, 53, 73],
            "10C": [477, 0, 53, 73],
            "JC": [530, 0, 53, 73],
            "QC": [583, 0, 53, 73],
            "KC": [636, 0, 53, 73],

            "AD": [0, 73, 53, 73],
            "2D": [53, 73, 53, 73],
            "3D": [106, 73, 53, 73],
            "4D": [159, 73, 53, 73],
            "5D": [212, 73, 53, 73],
            "6D": [265, 73, 53, 73],
            "7D": [318, 73, 53, 73],
            "8D": [371, 73, 53, 73],
            "9D": [424, 73, 53, 73],
            "10D": [477, 73, 53, 73],
            "JD": [530, 73, 53, 73],
            "QD": [583, 73, 53, 73],
            "KD": [636, 73, 53, 73],

            "AH": [0, 146, 53, 73],
            "2H": [53, 146, 53, 73],
            "3H": [106, 146, 53, 73],
            "4H": [159, 146, 53, 73],
            "5H": [212, 146, 53, 73],
            "6H": [265, 146, 53, 73],
            "7H": [318, 146, 53, 73],
            "8H": [371, 146, 53, 73],
            "9H": [424, 146, 53, 73],
            "10H": [477, 146, 53, 73],
            "JH": [530, 146, 53, 73],
            "QH": [583, 146, 53, 73],
            "KH": [636, 146, 53, 73],

            "AS": [0, 219, 53, 73],
            "2S": [53, 219, 53, 73],
            "3S": [106, 219, 53, 73],
            "4S": [159, 219, 53, 73],
            "5S": [212, 219, 53, 73],
            "6S": [265, 219, 53, 73],
            "7S": [318, 219, 53, 73],
            "8S": [371, 219, 53, 73],
            "9S": [424, 219, 53, 73],
            "10S": [477, 219, 53, 73],
            "JS": [530, 219, 53, 73],
            "QS": [583, 219, 53, 73],
            "KS": [636, 219, 53, 73],
            "xx": [689, 0, 53, 73],
            "xx1": [689, 73, 53, 73],
            "xx2": [742, 0, 53, 73],
            "xx3": [689, 146, 53, 73],
            "xx4": [689, 219, 53, 73],
        };

        this.hiddencards = [false, false, false, false, false, false];
        this.CardStore = [];

        this.cardZeroRef = React.createRef();
        this.cardOneRef = React.createRef();
        this.cardTwoRef = React.createRef();
        this.cardThreeRef = React.createRef();
        this.cardFourRef = React.createRef();
        this.cardFiveRef = React.createRef();
        this.groupCards = React.createRef();
    }

    ReBet() {
        if(this.cardOneRef.current){
            this.cardOneRef.current.endGame()
        }
        this.hiddencards = [false, false, false, false, false, false];
        this.CardStore = [];

        this.setState({
                x: (this.width - 26),
                y: 50,
                cardCount: 2,
                isFront: true,
                cardZeroAlpha: 1,
                cardOneAlpha: 1,
                cardTwoAlpha: 1,
                cardThreeAlpha: 1,
                cardFourAlpha: 1,
                cardFiveAlpha: 1,
                playerCardZero: "TC",
                playerCardOne: "TS",
                playerCardTwo: "TD",
                playerCardThree: "TC",
                playerCardFour: "TC",
                playerCardFive: "TC",
                cardStyle: CardDefault,
          
        });


    }


    showPreviousDealercards = (card, text, cnt) =>{

        console.log("text", text, "this.mapCards[text] :", this.mapCards[text]);
        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
            this.countcards = Number(cnt)
        }

        if (cnt !== undefined) {
            switch (card) {
                case "cardZero":
                    this.hiddencards[0] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {

                                this.cardZeroRef.current?.showPreviousDealercards();
                            } catch (e) { console.log(e); 
                            }
                        });
                    }, 1);
                    break;
                case "cardOne":
                    this.hiddencards[1] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            this.cardOneRef.current?.showPreviousDealercards();
                        });
                    }, 1);
                    break;
                case "cardTwo":
                    this.hiddencards[2] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            this.cardTwoRef.current?.showPreviousDealercards();
                        });
                    }, 1);

                    break;
                case "cardThree":
                    this.hiddencards[3] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        this.cardThreeRef.current?.showPreviousDealercards();
                    }, 1);

                    break;
                case "cardFour":
                    this.hiddencards[4] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        this.cardFourRef.current?.showPreviousDealercards();
                    }, 1);

                    break;
                case "cardFive":
                    this.hiddencards[5] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        this.cardFiveRef.current?.showPreviousDealercards();
                    }, 1);

                    break;
                default:
                    break;
            }
        }



    }



    addCards(card, text, cnt) {

        console.log("text", text, "this.mapCards[text] :", this.mapCards[text]);
        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
            this.countcards = Number(cnt)
        }

        // if (text === "xx") {
        //     switch (this.backCard) {
        //         case "default":
        //             text = "xx";
        //             break;
        //         case "backcard1":
        //             text = "xx1";
        //             break;
        //         case "backcard2":
        //             text = "xx2";
        //             break;
        //         case "backcard3":
        //             text = "xx3";
        //             break;
        //         case "backcard4":
        //             text = "xx4";
        //             break;
        //         default:
        //             break;
        //     }
        // }


        if (cnt !== undefined) {
            switch (card) {
                case "cardZero":
                    this.hiddencards[0] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {

                                this.cardZeroRef.current?.animateCard();
                            } catch (e) { console.log(e); 
                            }
                        });
                    }, 250);
                    break;
                case "cardOne":
                    this.hiddencards[1] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            this.cardOneRef.current?.animateCard();
                        });
                    }, 500);
                    break;
                case "cardTwo":
                    this.hiddencards[2] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            this.cardTwoRef.current?.animateCard();
                        });
                    }, 750);

                    break;
                case "cardThree":
                    this.hiddencards[3] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        this.cardThreeRef.current?.animateCard();
                    }, 1000);

                    break;
                case "cardFour":
                    this.hiddencards[4] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        this.cardFourRef.current?.animateCard();
                    }, 1250);

                    break;
                case "cardFive":
                    this.hiddencards[5] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        this.cardFiveRef.current?.animateCard();
                    }, 1500);

                    break;
                default:
                    break;
            }
        }
    }



    revealDealerCards = (card, text, cnt) => {
        console.log("card", card, "text", text, "cnt", cnt)
        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
        }

        // if (text === "xx") {
        //     switch (this.backCard) {
        //         case "default":
        //             text = "xx";
        //             break;
        //         case "backcard1":
        //             text = "xx1";
        //             break;
        //         case "backcard2":
        //             text = "xx2";
        //             break;
        //         case "backcard3":
        //             text = "xx3";
        //             break;
        //         case "backcard4":
        //             text = "xx4";
        //             break;
        //         default:
        //             break;
        //     }
        // }


        if (cnt !== undefined) {
            switch (card) {
                case "cardZero":
                    setTimeout(() => {
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {

                                this.cardZeroRef.current?.revealdealerMaskCard();

                            } catch (e) { console.log(e); 
                            }
                        });
                    }, 150);
                    break;
                case "cardOne":
                    setTimeout(() => {
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            this.cardOneRef.current?.revealdealerMaskCard();


                        });
                    }, 300);
                    break;
                case "cardTwo":
                    setTimeout(() => {
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            this.cardTwoRef.current?.revealdealerMaskCard();

                        });
                    }, 450);

                    break;
                case "cardThree":
                    setTimeout(() => {
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        this.cardThreeRef.current?.revealdealerMaskCard();

                    }, 600);

                    break;
                case "cardFour":
                    setTimeout(() => {
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        this.cardFourRef.current?.revealdealerMaskCard();

                    }, 750);

                    break;
                case "cardFive":
                    setTimeout(() => {
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        this.cardFiveRef.current?.revealdealerMaskCard();
                    }, 900);

                    break;
                default:
                    break;
            }
        }

    }



    dealerDrawcard(card, text, cnt) {
        console.log("card", card, "text", text, "cnt", cnt)
        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
        }

        if (cnt !== undefined) {
            switch (card) {
                case "cardZero":
                    setTimeout(() => {
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {

                                this.cardZeroRef.current?.DrawanimateCard();

                            } catch (e) { console.log(e);
                             }
                        });
                    }, 250);
                    break;
                case "cardOne":
                    setTimeout(() => {
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            this.cardOneRef.current?.DrawanimateCard();


                        });
                    }, 500);
                    break;
                case "cardTwo":
                    setTimeout(() => {
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            this.cardTwoRef.current?.DrawanimateCard();

                        });
                    }, 750);

                    break;
                case "cardThree":
                    setTimeout(() => {
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        this.cardThreeRef.current?.DrawanimateCard();

                    }, 1000);

                    break;
                case "cardFour":
                    setTimeout(() => {
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        this.cardFourRef.current?.DrawanimateCard();

                    }, 1250);

                    break;
                case "cardFive":
                    setTimeout(() => {
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        this.cardFiveRef.current?.DrawanimateCard();
                    }, 1500);

                    break;
                default:
                    break;
            }
        }

    }






    SuffleCard(card, text, cnt) {
        console.log(this.CardStore)
        console.log(text)

        console.log("card", card, "text", text, "cnt", cnt)
        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
        }

        if (cnt !== undefined) {
            switch (card) {
                case "cardZero":
                    setTimeout(() => {
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {
                                for (let i = 0; i < this.CardStore.length; i++) {
                                    if (this.CardStore[i].text == text && i != 0) {

                                        this.cardZeroRef.current?.SuffleCard(this.CardStore[i]);
                                        break;
                                    }
                                }
                            } catch (e) { console.log(e);
                             }
                        });
                    }, 150);
                    break;
                case "cardOne":
                    setTimeout(() => {
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            for (let i = 0; i < this.CardStore.length; i++) {
                                if (this.CardStore[i].text == text && i != 1) {
                                    this.cardOneRef.current?.SuffleCard(this.CardStore[i]);
                                    break;
                                }
                            }
                        });
                    }, 300);
                    break;
                case "cardTwo":
                    setTimeout(() => {
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            for (let i = 0; i < this.CardStore.length; i++) {
                                if (this.CardStore[i].text == text && i != 2) {
                                    this.cardTwoRef.current?.SuffleCard(this.CardStore[i]);
                                    break;
                                }
                            }
                        });
                    }, 450);

                    break;
                case "cardThree":
                    setTimeout(() => {
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        for (let i = 0; i < this.CardStore.length; i++) {
                            if (this.CardStore[i].text == text && i != 3) {
                                this.cardThreeRef.current?.SuffleCard(this.CardStore[i]);
                                break;
                            }
                        }
                    }, 600);

                    break;
                case "cardFour":
                    setTimeout(() => {
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        for (let i = 0; i < this.CardStore.length; i++) {
                            if (this.CardStore[i].text == text && i != 4) {
                                this.cardFourRef.current?.SuffleCard(this.CardStore[i]);
                                break;
                            }
                        }
                    }, 750);

                    break;
                case "cardFive":
                    setTimeout(() => {
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        for (let i = 0; i < this.CardStore.length; i++) {
                            if (this.CardStore[i].text == text && i != 5) {
                                this.cardFiveRef.current?.SuffleCard();
                                break;
                            }
                        }
                    }, 900);

                    break;
                default:
                    break;
            }
        }

    }




    UpdateCard(data) {
        console.log(data)
        this.setState({ x: data.x, y: data.y })
    }

    XY(data) {

        if (data.index == -1) {

            this.CardStore.push(data)
        } else {
            this.CardStore[data.index] = data;
        }
        console.log(this.CardStore)
    }



    render() {

        return (


            <Group ref={this.groupCards}>


                {this.hiddencards[0] && <DealerCard ref={this.cardZeroRef}
                    // x={this.props.x - (60)} y={this.props.y - (6)}
                    x={this.props.activeScreen===1 ? this.props.x - (60) : this.props.x - (60)} y={this.props.y - (20)}

                    frame={this.mapCards[this.state.playerCardZero]}
                    scaleX={this.props.scaleX} scaleY={this.props.scaleY} alpha={this.state.cardZeroAlpha} cardStyle={this.state.cardStyle}
                    angled={335} delay={100} XY={this.XY.bind(this)}
                    text={this.state.playerCardZero} x1={this.state.x} y1={this.state.y}
                    UpdateCard={this.UpdateCard.bind(this)} index={0}
                    selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                />}

                {this.hiddencards[1] && <DealerCard ref={this.cardOneRef}
                    // x={this.props.x - (10)} y={this.props.y - (6)}
                    x={this.props.activeScreen===1 ? this.props.x - (0) : this.props.x - (20)} y={this.props.y - (20)}
                    frame={this.mapCards[this.state.playerCardOne]}
                    scaleX={this.props.scaleX} scaleY={this.props.scaleY} alpha={this.state.cardOneAlpha} cardStyle={this.state.cardStyle}
                    angled={345} delay={200} XY={this.XY.bind(this)}
                    text={this.state.playerCardOne} x1={this.state.x} y1={this.state.y}
                    UpdateCard={this.UpdateCard.bind(this)} index={1}
                    selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}

                ></DealerCard>}

                {this.hiddencards[2] && <DealerCard ref={this.cardTwoRef}
                    // x={this.props.x + (40)} y={this.props.y - (6)}
                    x={this.props.activeScreen===1 ? this.props.x + (60) : this.props.x + (20)} y={this.props.y - (20)}
                    frame={this.mapCards[this.state.playerCardTwo]}
                    scaleX={this.props.scaleX} scaleY={this.props.scaleY} alpha={this.state.cardTwoAlpha}
                    cardStyle={this.state.cardStyle} angled={355} delay={400} XY={this.XY.bind(this)}
                    text={this.state.playerCardTwo} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}
                    selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                    index={2}
                ></DealerCard>}

                {this.hiddencards[3] && <DealerCard ref={this.cardThreeRef}
                    // x={this.props.x + (100)} y={this.props.y - (6)}
                    x={this.props.activeScreen===1 ? this.props.x + (120) : this.props.x + (60)} y={this.props.y - (20)}
                    frame={this.mapCards[this.state.playerCardThree]}
                    scaleX={this.props.scaleX} scaleY={this.props.scaleY} alpha={this.state.cardThreeAlpha}
                    cardStyle={this.state.cardStyle} angled={8} delay={600} XY={this.XY.bind(this)}
                    text={this.state.playerCardThree} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}
                    selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                    index={3}
                ></DealerCard>}


                {this.hiddencards[4] && <DealerCard ref={this.cardFourRef}
                    // x={this.props.x + (150)} y={this.props.y - (6)}
                    x={this.props.activeScreen===1 ? this.props.x + (180) : this.props.x + (100)} y={this.props.y - (20)}
                    frame={this.mapCards[this.state.playerCardFour]}
                    scaleX={this.props.scaleX} scaleY={this.props.scaleY} alpha={this.state.cardFourAlpha}
                    cardStyle={this.state.cardStyle} angled={17} delay={800} XY={this.XY.bind(this)}
                    text={this.state.playerCardFour} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}
                    selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                    index={4}
                ></DealerCard>}

                {this.hiddencards[5] && <DealerCard ref={this.cardFiveRef}
                    x={this.props.activeScreen===1 ? this.props.x + (240) : this.props.x + 185} y={this.props.y - (6)}
                    frame={this.mapCards[this.state.playerCardFive]}
                    scaleX={this.props.scaleX} scaleY={this.props.scaleY} alpha={this.state.cardFiveAlpha}
                    cardStyle={this.state.cardStyle} angled={25} delay={750} XY={this.XY.bind(this)}
                    text={this.state.playerCardFive} x1={this.state.x} y1={this.state.y}
                    UpdateCard={this.UpdateCard.bind(this)}
                    index={5}
                ></DealerCard>}
            </Group>


        )
    }
}


export default DealerCardsContainer;